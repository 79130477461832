function setToken(token) {
    localStorage.setItem('token', token)
}

function getToken() {
    return localStorage.getItem('token')
}

function removeToken() {
    localStorage.removeItem('token')
}

function saveLoginInfo(userInfo) {
    localStorage.setItem('loginInfo', JSON.stringify(userInfo))
}

function getLoginInfo() {
    const loginInfo = localStorage.getItem('loginInfo')
    if(loginInfo) {
        return JSON.parse(loginInfo)
    }
    return {
        avatar: '',
        nickname: '',
        username: '',
    }
}

function clearLoginInfo() {
    localStorage.removeItem('loginInfo')
}

/**
 * 解析 url 中的参数
 * @param url
 * @returns {Object}
 */
function parseUrlParams(url) {
    const params = {}
    if (!url || url === '' || typeof url !== 'string') {
        return params
    }
    const paramsStr = url.split('?')[1]
    if (!paramsStr) {
        return params
    }
    const paramsArr = paramsStr.replace(/&|=/g, ' ').split(' ')
    for (let i = 0; i < paramsArr.length / 2; i++) {
        const value = paramsArr[i * 2 + 1]
        params[paramsArr[i * 2]] = value === 'true' ? true : (value === 'false' ? false : value)
    }
    return params
}

function friendlyFormatTime(dateTime) {
    dateTime = dateTime ? dateTime.replace(/-/g, '/') : ''
    const dt = new Date(dateTime)
    return dt.friendlyFormatTime()
}

function escapeHtml(str) {
    return str
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;");
}

function replaceAll(str, find, replace) {
    return str.split(find).join(replace);
}

module.exports = {
    setToken,
    getToken,
    removeToken,
    saveLoginInfo,
    getLoginInfo,
    clearLoginInfo,
    parseUrlParams,
    friendlyFormatTime,
    escapeHtml,
    replaceAll,
}
