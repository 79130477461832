import axios from 'axios'
import {getToken} from '@/utils'
import { Dialog } from 'vant'
//请求地址
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL ? process.env.VUE_APP_API_BASE_URL : 'http://127.0.0.1'
//设置超时时间
axios.defaults.timeout = 50000
// post请求头
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8'
// 需服务端处理跨域
//axios.defaults.withCredentials = true

export default (vm) => {
    // 请求拦截器
    axios.interceptors.request.use(
        config => {
            // 在请求发送之前做一些处理
            //
            const token = getToken()
            if(token) {
                config.headers['Token'] = token
            }
            return config
        },
        error => {
            // 发送失败
            Promise.reject(error)
        }
    )

    // 响应拦截器（处理响应数据）
    axios.interceptors.response.use(
        (resp ) => {
            // console.log('axios resp ', resp)
            const data = resp.data
            if(data.code != '200'){
                if(data.code == '401'){
                    const token = getToken()
                    if(token) {
                        vm.$store.commit('clear_login_info')
                        Dialog.confirm({
                            title: '登出提示',
                            message: data.msg,
                            confirmButtonText: '重新登录',
                        }).then(() => {
                            vm.$router.push({name: 'login'})
                            //location.reload()
                        }).catch(() => {
                            // on cancel
                        });
                    }
                }
                /*if(data.code == '401'){
                    Dialog.confirm({
                        title: '登出提示',
                        message: data.msg,
                        confirmButtonText: '重新登录',
                    }).then(() => {
                        vm.$store.commit('clear_login_info')
                        vm.$router.push({name: 'login'})
                        //location.reload()
                    }).catch(() => {
                        // on cancel
                    });
                }*/
                return Promise.reject(new Error(data.msg))
            }
            return data
        },
        (error) => {
            //console.log('axios error ', error)
            if (error && error.response){
                if (error.response.data && error.response.data.msg !== '') {// 优先显示接口返回的错误信息
                    error.message = error.response.data.msg
                }else { // 否则判断显示错误信息
                    switch (error.response.status) {
                        case 400: error.message = '请求错误'; break
                        case 401: error.message = '未授权，请登录'; break
                        case 403: error.message = '拒绝访问'; break
                        case 404: error.message = `请求地址出错: ${error.response.config.url}`; break
                        case 408: error.message = '请求超时'; break
                        case 500: error.message = '服务器内部错误'; break
                        case 501: error.message = '服务未实现'; break
                        case 502: error.message = '网关错误'; break
                        case 503: error.message = '服务不可用'; break
                        case 504: error.message = '网关超时'; break
                        case 505: error.message = 'HTTP版本不受支持'; break
                        default: break
                    }
                }
                if (error.response.status == 401) {
                    const token = getToken()
                    if(token) {
                        vm.$store.commit('clear_login_info')
                        Dialog.confirm({
                            title: '登出提示',
                            message: error.message,
                            confirmButtonText: '重新登录',
                        }).then(() => {
                            vm.$router.push({name: 'login'})
                            //location.reload()
                        }).catch(() => {
                            // on cancel
                        });
                    }
                }
                /*if (error.response.status == 401) {
                    Dialog.confirm({
                        title: '登出提示',
                        message: error.message,
                        confirmButtonText: '重新登录',
                    }).then(() => {
                        vm.$store.commit('clear_login_info')
                        vm.$router.push({name: 'login'})
                        //location.reload()
                    }).catch(() => {
                        // on cancel
                    });
                }*/
            }
            return Promise.reject(error);
        }
    )
}

