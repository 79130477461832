import axios from 'axios'

async function request(api, params, config) {
    let method = api.method.toLowerCase()
    switch (method) {
        case 'get':
            return axios.get(api.path, {params, ...config})
        case 'post':
            return axios.post(api.path, params, config)
        case 'upload':
            return axios.post(api.path, params, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
        default:
            return axios.get(api.path, {params, ...config})
    }
}

export default request
