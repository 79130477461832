import {_message_getUnreadNum} from '@/service/api'
import request from '@/service/request'
export default {
    state :{
        unread:{
            chatUnreadNum: 0,
            noticeUnreadNum: 0,
            items: [],
        }
    },
    mutations :{
        set_unread(state, unread){
            state.unread = unread
        },
    },
    actions :{
        getUnread({commit}, params){
            return new Promise((resolve, reject) => {
                request(_message_getUnreadNum, params).then(res => {
                    // console.log('getUnreadNum', res)
                    commit('set_unread', res.data)
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        }
    }
}
