/*
	时间格式化 yyyy-MM-dd hh:mm:ss

	1、在main.js中配置下列代码
	import '@/utils/date-format.js'

	2、使用的方式
	new Date().format()
	new Date().format('yyyy-MM-dd')
*/

Date.prototype.format = function(fmt = 'yyyy-MM-dd hh:mm:ss') {
	const o = {
		'M+': this.getMonth() + 1, //月份
		'd+': this.getDate(), //日
		'h+': this.getHours(), //小时
		'm+': this.getMinutes(), //分
		's+': this.getSeconds(), //秒
		'q+': Math.floor((this.getMonth() + 3) / 3), //季度
		'S': this.getMilliseconds(), //毫秒
	}
	if (/(y+)/.test(fmt)) {
		fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length))
	}
	for (const k in o) {
		if (new RegExp('(' + k + ')').test(fmt)) {
			fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length))
		}
	}
	return fmt
}

//时间简化 转换为 刚刚 今天 昨天 之类
Date.prototype.friendlyFormatTime = function() {
	const ts = this.getTime()
	const second = 1000
	const minute = second * 60
	const hour = minute * 60
	const day = hour * 24
	const month = day * 31

	const diffValue = new Date().getTime() - ts //时间差

	let result = ''
	if (diffValue < 0) {
		return result
	}
	// const seconds = Math.floor(diffValue / second)
	const minutes = Math.floor(diffValue / minute)
	const hours = Math.floor(diffValue / hour)
	const days = Math.floor(diffValue / day)
	const months = Math.floor(diffValue / month)

	if(minutes < 1) {
		result = '刚刚'
	}else if (minutes >= 1 && hours < 1) {
		result = minutes + '分钟前'
	}else if (hours >= 1 && days < 1) {
		result = hours + '小时前'
	}else if (days >= 1 && months < 1) {
		result = days + '天前'
	}else if (months >= 1 && months < 12) {
		result = months + '月前'
	}else {
		const year = this.getFullYear()
		const mon = this.getMonth() + 1 < 10 ? '0' + (this.getMonth() + 1) : this.getMonth() + 1
		const dd = this.getDate() < 10 ? '0' + this.getDate() : this.getDate()
		const hh = this.getHours() < 10 ? "0" + this.getHours() : this.getHours()
		const min = this.getMinutes() < 10 ? "0" + this.getMinutes() : this.getMinutes()
		// const sec = this.getSeconds() < 10 ? "0" + this.getSeconds() : this.getSeconds()
		result = year + '年' + mon + '月' + dd + '日 ' + hh + ':' + min
	}
	return result
}
